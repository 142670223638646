import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import RefreshIcon from '@mui/icons-material/Refresh'
import SettingsIcon from '@mui/icons-material/Settings'
import {MenuItem, IconButton, Typography, Tabs, Tab} from '@mui/material'
import {useIsFetching, useQueryClient} from '@tanstack/react-query'
import {If} from 'babel-plugin-jsx-control-statements'
import * as apps from 'constants/apps'
import type {FC, ReactNode} from 'react'
import {useMemo} from 'react'
import {NavLink} from 'react-router-dom'
import LogoDuf from '../../assets/logo-white.svg'
import * as clientRoutes from '../../constants/routes'
import {useAuth} from '../../hooks/auth'
import usePermitted from '../../hooks/usePermitted'
import useResourceMutationSubscription from '../../hooks/useResourceMutationSubscription'
import useRouteMatch from '../../hooks/useRouteMatch'
import {AppBar, Brand, ButtonMenu} from '../visual'
import Link from './Link'
import LinkBehavior from './LinkBehaviour'


type AppShellProps = {
  onMenuClick: () => void,
  openMenu: boolean,
  children: ReactNode,
}

const AppShell: FC<AppShellProps> = ({onMenuClick, openMenu, children}) => {
  const {logout, refreshAuth} = useAuth()
  const isFetching = useIsFetching()
  const queryClient = useQueryClient()
  useResourceMutationSubscription()

  const handleRefresh = async () => {
    await refreshAuth()
    await queryClient.invalidateQueries({
      type: 'all',
      refetchType: 'active',
    })
  }

  const memoizedChildren = useMemo(() => children, [openMenu]) // eslint-disable-line react-hooks/exhaustive-deps

  const permittedAppOlup = usePermitted({app: apps.OLUP})
  const permittedAppUtz = usePermitted({app: apps.UTZ})

  const catchAllOlup = `${clientRoutes.OLUP}/*`
  const catchAllUtz = `${clientRoutes.UTZ}/*`

  const routeMatch = useRouteMatch([catchAllOlup, catchAllUtz])
  const currentTab = routeMatch?.pattern?.path

  return (
    <>
      <AppBar
          brand={<Link to="/"><Brand src={LogoDuf} alt="Logo Drážního úřadu" /></Link>}
          title={
            <NavLink to="/">
              {({isActive}) => (
                <Typography component="h2" color={isActive ? 'primary.contrastText' : 'text.secondary'}>
                  <strong>Aplikace DÚ</strong>
                </Typography>
              )}
            </NavLink>
          }
          isFetching={Boolean(isFetching)}
          onMenuClick={onMenuClick}
      >
        <Tabs variant="standard" value={currentTab}>
          <If condition={permittedAppOlup}>
            <Tab component={Link} to={clientRoutes.OLUP} value={catchAllOlup} label="OLUP" />
          </If>
          <If condition={permittedAppUtz}>
            <Tab component={Link} to={clientRoutes.UTZ} value={catchAllUtz} label="UTZ" />
          </If>
        </Tabs>
        <AppBar.Divider />
        <IconButton onClick={handleRefresh}>
          <RefreshIcon />
        </IconButton>
        <ButtonMenu icon={<AccountCircleIcon />}>
          <MenuItem component={LinkBehavior} href={clientRoutes.PROFILE}>
            <SettingsIcon />
            Profil
          </MenuItem>
          <MenuItem onClick={logout}>
            <PowerSettingsNewIcon />
            Odhlásit
          </MenuItem>
        </ButtonMenu>
      </AppBar>
      {memoizedChildren}
    </>
  )
}

export default AppShell
