import {Link, Typography} from '@mui/material'
import {FORM_ERROR} from 'final-form'
import {isError} from 'lodash'
import type {ComponentProps} from 'react'
import {useNavigate} from 'react-router'
import * as clientRoutes from '../../../constants/routes'
import {useAuth} from '../../../hooks/auth'
import {Footer, Login as VisualLogin} from '../../visual'
import LoginForm from './LoginForm'


const Login = () => {
  const {login} = useAuth()
  const navigate = useNavigate()

  const handleLogin: ComponentProps<typeof LoginForm>['onSubmit'] = async (values) => {
    const res = await login(values)

    if (isError(res)) {
      return {[FORM_ERROR]: 'Špatný email nebo heslo'}
    }

    if (res.userId) {
      navigate(clientRoutes.HOME, {replace: true})
    }
  }

  return (
    <VisualLogin
        note={
          <Typography color="info.main" variant="caption">
            Nemáte přihlašovací údaje?&nbsp;
            <Link component="a" href="mailto:itpozadavky@ducr.cz" color="info.main" underline="always">
              Kontaktujte podporu
            </Link>
          </Typography>
        }
        footer={<Footer color="info.main" from={1997} version="1.0.0" />}
    >
      <Typography variant="subtitle1" color="primary">
        <strong>Aplikace DÚ</strong>
      </Typography>
      <LoginForm onSubmit={handleLogin} />
    </VisualLogin>
  )
}

export default Login
